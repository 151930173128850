<template>
  <div class="md-layout md-alignment-center">
    <div class="md-layout-item md-small-hide">
      <img src="@/assets/img/slide.jpg" alt="Logo" class="slide-image" />
    </div>
    <div
      class="
        md-layout-item
        md-size-50
        md-medium-size-50
        md-small-size-70
        md-xsmall-size-100
      "
    >
      <ValidationListener
        @getIsValid="(isValid) => (auth.isFormValid = isValid)"
      >
        <login-card>
          <h3 slot="title" class="title"><br />Log in</h3>
          <p slot="subheader" class="md-caption text-center m-0">
            Please enter your User Information
          </p>
          <div slot="inputs">
            <ValidationHandler
              :name="auth.postData.userName"
              rules="required|email"
              v-slot="{ error }"
            >
              <text-input
                label="Email..."
                placeholder="Enter Email"
                :required="false"
                v-model="auth.postData.userName"
                :error="error"
                icon="mail_outline"
                @submit="handleLogin()"
              />
            </ValidationHandler>
            <ValidationHandler
              :name="auth.postData.password"
              rules="required|space_exclude"
              v-slot="{ error }"
            >
              <text-input
                type="password"
                label="Password..."
                v-model="auth.postData.password"
                placeholder="Enter Password"
                :error="error"
                icon="lock_outline"
                :required="false"
                @submit="handleLogin()"
              />
            </ValidationHandler>
            <div class="text-right" @click="forgotPwd()">
              <span class="hover">Forgot Password ?</span>
            </div>
            <!-- <router-link to="/reset-psw" class="text-right hover" 
              >Forgot Password</router-link
            > -->
          </div>
          <md-button slot="footer" @click="cancelLogin()">Cancel</md-button>
          <spinner
            slot="footer"
            style="margin-bottom: 10px"
            :diameter="25"
            v-if="auth.authenticating"
          ></spinner>
          <md-button
            v-else
            slot="footer"
            class="md-primary"
            @click="handleLogin()"
            >Sign In</md-button
          >
        </login-card>
      </ValidationListener>
    </div>
  </div>
</template>

<script>
//import User from "../models/User";
import LoginCard from "../../components/Cards/LoginCard.vue";

export default {
  components: {
    "login-card": LoginCard,
  },
  inject: ["auth", "handleLogin", "cancelLogin", "forgotPwd"],
};
</script>

<style scoped>
span:hover {
  color: rgba(0, 0, 255, 0.747);
}
span:hover {
  cursor: pointer;
}
</style>
